
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import PropertyModal from './property-modal.vue'
import EntityModelManger from '@/libs/entitymodel'
import { BaseModal } from '@/libs/modal-helper'
import Treeselect from '@riophae/vue-treeselect'
import OrgInfo from '@/libs/entitymodel/entity-orginfo'
import Entity from '@/libs/entitymodel/entity'

interface TreeNode{
    /// 节点id
    id: string;
    /// 叶子标题
    label: string;
    /// 是否可以选择
    isDisabled: boolean;
    /// 子节点
    children?: TreeNode[];
}
/**
 * 设置实体父亲实体对话框
 */
@Component({
  name: 'setEntityParentModal', components: { PropertyModal, Treeselect }
})
export default class SetEntityParentModal extends Vue implements BaseModal {
  /// 原来实体的父实体
  @Prop({
    type: String,
    required: false
  })
  value?: string

  /**
   * 当前实体的uuid
   */
  @Prop({
    type: String,
    required: true
  })
  entityUuid!: string

  curValue?: string

  // 实体模型对象
  @Prop({
    type: Object,
    required: true
  })
  entityModelManger!: EntityModelManger

  selectEntity (node: any, id: string) {
    this.curValue = undefined
  }

  /// 取得树选择节点
  get options () {
    const res: TreeNode[] = []
    for (const key in this.entityModelManger.orgs) {
      const item = this.entityModelManger.orgs[key]
      const org = this.orgToTreeData(item)
      if (item.parentOrg === undefined && org) {
        res.push(org)
      }
    }

    return res
  }

  /**
   * 将组织信息转为树节点
   */
  private orgToTreeData (org: OrgInfo): TreeNode|undefined {
    const children = org.children.map((ch) => this.orgToTreeData(this.entityModelManger.orgs[ch.uuid]))

    const re: TreeNode = {
      id: org.uuid,
      label: org.name,
      isDisabled: false,
      children: children.filter((item) => item !== undefined) as TreeNode[]
    }

    // 添加组织节点的实体对象
    const entities = this.entityModelManger.getEnitiesByOrgId(org.uuid)
    if (entities) {
      const children = entities.map((item) => this.entityToTreeNode(item))
      re.children?.push(...children)
    }
    /// 没有子的节点不可以选择
    if (re.children?.length === 0) {
      re.isDisabled = true
    }

    if (re.children?.length === 0) return undefined

    return re
  }

  /**
 * 实体为树节点
 */
  private entityToTreeNode (entity: Entity) {
    const re: TreeNode = {
      id: entity.uuid,
      label: `${entity.title}(${entity.name})`,
      isDisabled: this.entityUuid === entity.uuid
    }
    return re
  }

  created () {
    this.watchValue(this.value)
  }

  @Watch('value')
  watchValue (value?: string) {
    this.curValue = value
  }

  show (): void {
    (this.$refs.dlg as PropertyModal).show()
  }

  close (): void {
    (this.$refs.dlg as PropertyModal).close()
  }

  doSave (): void {
    this.$Modal.confirm({
      title: '确认',
      content: '确认要修改实体的派生实体吗?',
      onOk: () => {
        this.$emit('onOk', this.curValue)
      }
    })
  }

  doCancel (): void {
    this.$emit('onCancel')
  }
}
