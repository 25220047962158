
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import PropertyModal from './property-modal.vue'
import showFieldSelectModal from './field-select-modal'
import { Form } from 'view-design'
import _ from 'lodash'
import { Index } from '@/libs/entitymodel/entity-index'
import Entity from '@/libs/entitymodel/entity'

@Component({
  name: 'indexModal', components: { PropertyModal }
})
export default class IndexModal extends Vue {
  /**
   * 对应的实体
   */
  @Prop({
    type: Object,
    required: false,
    default: () => {
      return {
        uuid: '',
        name: '',
        title: '',
        fields: []
      }
    }
  })
  entity!: Entity

  /// 当前绑定的约束对象
  @Prop({
    type: Object,
    required: false,
    default: () => {
      return {
        uuid: '',
        name: '',
        title: '',
        fields: []
      }
    }
  })
  value!: Index

  curValue: Index=_.cloneDeep(this.value)

  /**
   * 表单较验
   */
  rules={
    title: [
      { required: true, message: '约束标题不能为空', trigger: 'blur' }
    ],
    name: [{ required: true, message: '约束名称不能为空', trigger: 'blur' },
      { required: true, type: 'string', pattern: /^[a-zA-Z][0-9a-zA-Z_]{1,}$/, trigger: 'blur', message: '只允许字母数字及下划线' }]
  }

  @Watch('value')
  watchValue (value: Index) {
    this.curValue = value
  }

  created () {
    this.watchValue(this.value)
  }

  /**
   * 当前字段是否有值
   */
  get hasFields () {
    return this.curValue.fields.length > 0
  }

  /// 计算uuid对应的字段名
  getFieldTitle (index: number) {
    const col = this.curValue.fields[index]
    const fieldIndex = this.entity.fields.findIndex((item) => item.uuid === col.uuid)
    const field = this.entity.fields[fieldIndex]

    return `${field.title}(${field.name})`
  }

  /// 打开修改字段对话框
  onModiField () {
    showFieldSelectModal(this.entity.fields, this.curValue.fields.map((item) => item.uuid), (fields: string[]) => {
      return new Promise<void>((resolve, reject) => {
        this.curValue.fields = fields.map((item) => {
          const oldIndex = this.curValue.fields.findIndex((col) => col.uuid === item)
          return {
            uuid: item,
            sort: oldIndex >= 0 ? this.curValue.fields[oldIndex].sort : 'asc' // 如果原来有同一字段用原来的排序
          }
        })
        resolve()
      })
    })
  }

  /// 删除字段
  delField (index: number) {
    /// 删除指定的字段
    this.curValue.fields.splice(index, 1)
  }

  /// 上移字段
  upField (index: number) {
    if (index <= 0) return
    const temp = this.curValue.fields.splice(index, 1)
    this.curValue.fields.splice(index - 1, 0, ...temp)
  }

  /// 下移字段
  downField (index: number) {
    if (index >= this.curValue.fields.length - 1) return
    const temp = this.curValue.fields.splice(index, 1)
    this.curValue.fields.splice(index + 1, 0, ...temp)
  }

  show (): void {
    (this.$refs.dlg as PropertyModal).show()
  }

  close (): void {
    (this.$refs.dlg as PropertyModal).close()
  }

  /// 保存处理
  doSave () {
    const form = this.$refs.form as Form

    form.validate((result) => {
      if (result && this.curValue.fields.length > 0) {
        this.$emit('onOk', this.curValue)
      } else {
        this.$Notice.error({
          title: '错误',
          desc: '数据检查失败!'
        })
      }
    })
  }

  /// 取消处理
  doCancel () {
    this.$emit('onCancel')
  }
}
