/**
 * 组织信息
 */
export default interface OrgInfo {
  uuid: string;
  name: string;
  title: string;
  children: OrgInfo[];

  // 以下字段需要从服务器加载回来后初始化
  parentOrg?: OrgInfo;

}

/**
 * 组织信息数据
 */
export interface OrgInfoData {
  /// 项目id
  projectId: number;

  /// 版本uuid
  versionUuid: string;

  /// 数据内容
  data: OrgInfo;

  /// 创建时间
  createTime: string;

  /// 修改时间
  modifyTime: string;

  /// 创建者
  creator: number;

  /// 修改者
  mender: number;
}

/**
 * 取得组织的全名
 * @param orgInfo
 */
export function getOrgFullName (orgInfo: OrgInfo | undefined) {
  if (orgInfo) {
    const arry = []
    arry.push(orgInfo.name)
    let node = orgInfo
    while (node.parentOrg) {
      arry.unshift(node.parentOrg.name)
      node = node.parentOrg
    }
    return arry.join('.')
  }
  return ''
}
