
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import PropertyModal from './property-modal.vue'
import EntityField from '@/libs/entitymodel/entity-field'
import { BaseModal } from '@/libs/modal-helper'

/**
 * 字段选择对话框
 */
@Component({
  name: 'fieldSelectModal',
  components: { PropertyModal }
})
export default class FieldSelectModal extends Vue implements BaseModal {
  /// 可以选择字段
  @Prop({
    type: Array,
    required: false,
    default: () => []
  })
  fields!: EntityField[]

  /// 选择的字段key列表
  @Prop({
    type: Array,
    required: false,
    default: () => []
  })
  selectedKeys!: string[]

  /**
   * 保存处理方法
   */
  @Prop({
    type: Function,
    required: false,
    default: () => {
      return new Promise<void>((resolve, reject) => {
        resolve()
      })
    }
  })
  onSave!: (data: string[]) => Promise<void>

  /// 可以选择的key
  targetKeys: string[]=[]

  /// 当前可以选择的列表
  get curFields () {
    const arry = this.fields.filter((item) => !item.transient)
    return arry.map((item) => {
      return {
        key: item.uuid,
        label: `${item.title}(${item.name})(${item.type})`,
        disable: false
      }
    })
  }

  created () {
    this.watchSelectedKeys(this.selectedKeys)
  }

  /**
   * 监听已经选择的keys
   */
  @Watch('selectedKeys')
  watchSelectedKeys (keys: string[]) {
    this.targetKeys = keys
  }

  // 取得字段的标题
  filedTitle (field: EntityField) {
    return `${field.title}(${field.name})(${field.type})`
  }

  show (): void {
    (this.$refs.dlg as PropertyModal).show()
  }

  close (): void {
    (this.$refs.dlg as PropertyModal).close()
  }

  /// 保存处理
  doSave () {
    this.$emit('onOk', this.targetKeys)
  }

  /// 取消处理
  doCancel () {
    this.$emit('onCancel')
  }

  /// 选择变化事件
  handleChange (keys: string[]) {
    this.targetKeys = keys
  }
}
