import { render, staticRenderFns } from "./setentity-parent-modal.vue?vue&type=template&id=420c9bc2&scoped=true&"
import script from "./setentity-parent-modal.vue?vue&type=script&lang=ts&"
export * from "./setentity-parent-modal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "420c9bc2",
  null
  
)

export default component.exports