import { render, staticRenderFns } from "./index-modal.vue?vue&type=template&id=ae20f2f2&scoped=true&"
import script from "./index-modal.vue?vue&type=script&lang=ts&"
export * from "./index-modal.vue?vue&type=script&lang=ts&"
import style0 from "./index-modal.vue?vue&type=style&index=0&id=ae20f2f2&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae20f2f2",
  null
  
)

export default component.exports