import EntityModelManger from '@/libs/entitymodel'
import { showModal } from '@/libs/modal-helper'
import modal from './setentity-parent-modal.vue'

/**
 * 弹出新增约束对话框
 * @param parent
 * @param onOk
 */
export function showSetParentEntityModal (entityUuid: string, curParentUuid: string|undefined, entityModelManger: EntityModelManger,
  onOk?: (data: string|undefined) => Promise<void>) {
  showModal<string|undefined>(modal, {
    props: {
      entityModelManger: entityModelManger,
      value: curParentUuid,
      entityUuid: entityUuid
    }
  }, true, onOk, undefined)
}
