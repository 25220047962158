import Entity from '@/libs/entitymodel/entity'
import Constraint from '@/libs/entitymodel/entity-constraint'
import { showModal } from '@/libs/modal-helper'
import modal from './constraint-modal.vue'

/**
 * 弹出新增约束对话框
 * @param parent
 * @param onOk
 */
export function showNewConstraintModal (entity: Entity,
  onOk?: (data: Constraint) => Promise<void>) {
  showModal<Constraint>(modal, {
    props: {
      entity: entity
    }
  }, true, onOk, undefined)
}

/**
 * 弹出约束对话框
 * @param parent
 * @param entity
 * @param constraint
 * @param onOk
 */
export function showModiConstraintModal (entity: Entity,
  constraint: Constraint, onOk?: (data: Constraint) => Promise<void>) {
  showModal<Constraint>(modal, {
    props: {
      entity: entity,
      value: constraint
    }
  }, true, onOk, undefined)
}
