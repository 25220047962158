import EntityField from '@/libs/entitymodel/entity-field'
import { showModal } from '@/libs/modal-helper'
import FieldSelectModal from './field-select-modal.vue'

/**
 * 显示字段选择对话框
 * @param parent 父节点
 * @param fields 选择的字段列表 如果新增请传入空的数组
 * @param onOk 确认回调
 */
export default function showFieldSelectModal (fields: EntityField[], selectedKeys: string[], onOk?: (data: string[]) => Promise<void>) {
  showModal<string[]>(FieldSelectModal, {
    props: {
      fields: fields,
      selectedKeys: selectedKeys
    }
  }, true, onOk, undefined)
}
