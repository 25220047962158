import OrgInfo from '@/libs/entitymodel/entity-orginfo'
import { showModal } from '@/libs/modal-helper'
import modal from './setentity-package.vue'

/**
 * 弹出新增约束对话框
 * @param parent
 * @param onOk
 */
export default function showSetEntityPackageModal (
  orgs: Record<string, OrgInfo>,
  orgUuid: string,
  onOk?: (newOrgUuid: string) => Promise<void>) {
  showModal<string>(modal, {
    props: {
      orgs: orgs,
      orgUuid: orgUuid
    }
  }, true, onOk, undefined)
}
