import { render, staticRenderFns } from "./leftjoin-modal.vue?vue&type=template&id=27a65940&scoped=true&"
import script from "./leftjoin-modal.vue?vue&type=script&lang=ts&"
export * from "./leftjoin-modal.vue?vue&type=script&lang=ts&"
import style0 from "./leftjoin-modal.vue?vue&type=style&index=0&id=27a65940&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27a65940",
  null
  
)

export default component.exports