
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import PropertyModal from './property-modal.vue'
import { Form } from 'view-design'
import { BaseModal } from '@/libs/modal-helper'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import EntityModelManger from '@/libs/entitymodel'
import OrgInfo from '@/libs/entitymodel/entity-orginfo'
import EntityField from '@/libs/entitymodel/entity-field'
import _ from 'lodash'
import { getShortUuid } from '@/libs/uuid'
import Entity from '@/libs/entitymodel/entity'
import DelCheck from '@/libs/entitymodel/entity-delcheck'

interface TreeNode {
  /// 节点id
  id: string;
  /// 叶子标题
  label: string;
  /// 是否可以选择
  isDisabled: boolean;
  /// 子节点
  children?: TreeNode[];
}

/**
 * 删除检查弹窗
 */
@Component({
  name: 'DeleteCheckModal',
  components: { PropertyModal, Treeselect }
})
export default class DeleteCheckModal extends Vue implements BaseModal {
  // 实体模型对象
  @Prop({
    type: Object,
    required: true
  })
  entityModelManger!: EntityModelManger

  // 关联实体
  @Prop({
    type: Object,
    required: true
  })
  entity!: Entity

  @Prop({
    type: Object,
    required: false,
    default: () => ({})
  })
  value!: DelCheck

  /**
   * 当前的值
   */
  curValue: DelCheck = _.cloneDeep(this.value)

  /// 实体标题
  getEntityTitle (uuid: string) {
    const entity = this.entityModelManger.entitiesMap[uuid]
    return `${entity.title}(${entity.name})`
  }

  /**
   * 取得实体已经添加的主表
   */
  get mainJoins () {
    const res: Array<any> = _.cloneDeep(this.entity.joins)

    res.push({
      uuid: getShortUuid(),
      /// 主表字段
      mainFieldUuid: null,
      /// 主实体uuid
      mainEnityUuid: null,
      /// 主体的别名
      mainEntityAlias: null,

      /// 联结字段名
      joinFieldUuid: null,
      /// 联结实体uuid
      joinEntityUuid: this.entity.uuid,
      /// 联结别名
      joinAlias: null,
      /// 联接条件脚本
      joinConditionScript: null
    })
    return res
  }

  /// 表单校验规则
  rules = {
    sourceEntityFieldUuid: [
      { required: true, message: '主字段不能为空', trigger: 'blur' }
    ],
    targetEntityUuid: [
      { required: true, message: '检查表不能为空', trigger: 'blur' }
    ],
    targetEntityFieldUuid: [
      { required: true, message: '检查字段不能为空', trigger: 'blur' }
    ],
    message: [
      { required: true, message: '约束提示不能为空', trigger: 'blur' }
    ]
  }

  /// 取得树选择节点
  get joinOptions () {
    const res: TreeNode[] = []
    for (const key in this.entityModelManger.orgs) {
      const item = this.entityModelManger.orgs[key]
      const org = this.orgToTreeData(item)
      if (item.parentOrg === undefined && org) {
        res.push(org)
      }
    }

    return res
  }

  /// 当前实体字段
  get joinFields () {
    if (!this.curValue.targetEntityUuid) return []
    return this.entityModelManger
      .getEntityAllFieldes(this.curValue.targetEntityUuid)
      .filter(e => !e.linkField)
  }

  /// 当前主实体的字段
  get mainFields () {
    return this.entityModelManger
      .getEntityAllFieldes(this.entity.uuid)
      .filter(e => !e.linkField)
  }

  /// 取得实体标题
  getFieldTitle (field: EntityField) {
    return `${field.title}(${field.name})`
  }

  /**
   * 将组织信息转为树节点
   */
  private orgToTreeData (org: OrgInfo): TreeNode | undefined {
    const children = org.children.map(ch =>
      this.orgToTreeData(this.entityModelManger.orgs[ch.uuid])
    )

    const re: TreeNode = {
      id: org.uuid,
      label: org.name,
      isDisabled: false,
      children: children.filter(item => item !== undefined) as TreeNode[]
    }

    // 添加组织节点的实体对象
    const entities = this.entityModelManger.getEnitiesByOrgId(org.uuid)
    if (entities) {
      const children = entities.map(item => this.entityToTreeNode(item))
      re.children?.push(...children)
    }
    /// 没有子的节点不可以选择
    if (re.children?.length === 0) {
      re.isDisabled = true
    }

    if (re.children?.length === 0) return undefined

    return re
  }

  /**
   * 实体为树节点
   */
  private entityToTreeNode (entity: Entity) {
    const re: TreeNode = {
      id: entity.uuid,
      label: `${entity.title}(${entity.name})`,
      isDisabled: false
    }
    return re
  }

  /**
   * 实体选择事件，当重新选择一个实体后，字段清除
   */
  private selectLeftEntity (node: any, id: string) {
    this.$set(this.curValue, 'targetEntityFieldUuid', undefined)
  }

  @Watch('value')
  watchValue (value: DelCheck) {
    const data = _.cloneDeep(value)
    this.curValue = data
    this.$set(this, 'curValue', data)
  }

  created () {
    this.watchValue(this.value)
  }

  show (): void {
    ;(this.$refs.dlg as PropertyModal).show()
  }

  close (): void {
    ;(this.$refs.dlg as PropertyModal).close()
  }

  doSave (): void {
    const form = this.$refs.form as Form

    form.validate(result => {
      if (result) {
        this.$emit('onOk', this.curValue)
      } else {
        this.$Notice.error({
          title: '错误',
          desc: '数据检查失败!'
        })
      }
    })
  }

  doCancel (): void {
    this.$emit('onCancel')
  }
}
