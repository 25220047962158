import Entity from '@/libs/entitymodel/entity'
import { Index } from '@/libs/entitymodel/entity-index'
import { showModal } from '@/libs/modal-helper'
import modal from './index-modal.vue'

/**
 * 弹出新增约束对话框
 * @param parent
 * @param onOk
 */
export function showNewIndexModal (entity: Entity,
  onOk?: (data: Index) => Promise<void>) {
  showModal<Index>(modal, {
    props: {
      entity: entity
    }
  }, true, onOk, undefined)
}

/**
 * 弹出约束对话框
 * @param parent
 * @param entity
 * @param constraint
 * @param onOk
 */
export function showModiIndexModal (entity: Entity,
  index: Index, onOk?: (data: Index) => Promise<void>) {
  showModal<Index>(modal, {
    props: {
      entity: entity,
      value: index
    }
  }, true, onOk, undefined)
}
