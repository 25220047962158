import EntityModelManger from '@/libs/entitymodel'
import Entity from '@/libs/entitymodel/entity'
import EntityLeftJoin from '@/libs/entitymodel/entity-leftjoin'
import { showModal } from '@/libs/modal-helper'
import modal from './leftjoin-modal.vue'

/**
 * 弹出新增约束对话框
 * @param parent
 * @param onOk
 */
export function showNewLeftJoinModal (entityModelManger: EntityModelManger, entity: Entity, onOk?: (data: EntityLeftJoin) => Promise<void>) {
  showModal<EntityLeftJoin>(modal, {
    props: {
      entityModelManger: entityModelManger,
      entity: entity
    }
  }, true, onOk, undefined)
}

/**
 * 弹出修改leftjoin对话框
 * @param join
 * @param entityModelManger
 * @param entity
 * @param onOk
 */
export function showEditLeftJoinModal (join: EntityLeftJoin, entityModelManger: EntityModelManger, entity: Entity, onOk?: (data: EntityLeftJoin) => Promise<void>) {
  showModal<EntityLeftJoin>(modal, {
    props: {
      entityModelManger: entityModelManger,
      entity: entity,
      value: join
    }
  }, true, onOk, undefined)
}
