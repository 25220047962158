
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import EntityModelManger from '@/libs/entitymodel'
import OrgInfo from '@/libs/entitymodel/entity-orginfo'
import { BaseModal } from '@/libs/modal-helper'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import PropertyModal from './property-modal.vue'
import { getterLockOrgIds } from '@/store'

interface TreeNode{
    /// 节点id
    id: string;
    /// 叶子标题
    label: string;
    /// 是否可以选择
    isDisabled: boolean;
    /// 子节点
    children?: TreeNode[];
}

@Component({
  name: 'setEntityPackage',
  components: { PropertyModal, Treeselect }
})
export default class SetEntityPackage extends Vue implements BaseModal {
  // 实体模型对象
  @Prop({
    type: Object,
    required: true
  })
  orgs!: Record<string, OrgInfo>

  /// 当前的组织id
  @Prop({
    type: String,
    required: true
  })
  orgUuid!: string

  curValue: string=this.orgUuid

  /// 取得树选择节点
  get options () {
    const res: TreeNode[] = []
    for (const key in this.orgs) {
      const item = this.orgs[key]
      const org = this.orgToTreeData(item)
      if (item.parentOrg === undefined && org) {
        res.push(org)
      }
    }

    return res
  }

  /**
   * 将组织信息转为树节点
   */
  private orgToTreeData (org: OrgInfo): TreeNode|undefined {
    const children = org.children.map((ch) => this.orgToTreeData(this.orgs[ch.uuid]))
    let data: TreeNode[]|undefined = children.filter((item) => item !== undefined) as TreeNode[]
    if (data.length === 0) data = undefined

    const re: TreeNode = {
      id: org.uuid,
      label: org.name,
      isDisabled: org.uuid === this.orgUuid,
      children: data
    }

    return re
  }

  selectOrg (node: TreeNode) {
    this.curValue = node.id
  }

  /// 保存处理
  doSave () {
    if (!this.curValue || this.curValue === '') {
      this.$Message.error('请选择一个组织')
      return
    }
    const lockedOrgIds = getterLockOrgIds()
    if (lockedOrgIds.findIndex((item) => item === this.curValue) < 0) {
      this.$Message.error('不能将实体移动到没有锁定的组织下，请先对该组织进行锁定')
      return
    }
    this.$emit('onOk', this.curValue)
  }

  /// 取消处理
  doCancel () {
    this.$emit('onCancel')
  }

  show (): void {
    (this.$refs.dlg as PropertyModal).show()
  }

  close (): void {
    (this.$refs.dlg as PropertyModal).close()
  }
}
