
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import {
  showNewConstraintModal,
  showModiConstraintModal
} from './modal/constraint-modal'
import { showNewIndexModal, showModiIndexModal } from './modal/index-modal'
import EntityModelManger from '@/libs/entitymodel'
import {
  showNewLeftJoinModal,
  showEditLeftJoinModal
} from './modal/leftjoin-modal'
import { showSetParentEntityModal } from './modal/setentity-parent-modal'
import _ from 'lodash'
import { getOrgFullName } from '@/libs/entitymodel/entity-orginfo'
import { Index } from '@/libs/entitymodel/entity-index'
import Constraint from '@/libs/entitymodel/entity-constraint'
import Entity from '@/libs/entitymodel/entity'
import showSetEntityPackageModal from './modal/setentity-package'
import { getterLockOrgIds } from '@/store'
import { SlickList, SlickItem } from 'vue-slicksort'
import DelCheck from '@/libs/entitymodel/entity-delcheck'
import { showDeleteCheckModal } from './modal/delete-check-modal'

/// 将驼峰命名字串，转为下划线字串
const toLineName = (hump: string) =>
  hump.replace(/([A-Z]|[0-9]+)/g, (a, l) => `_${l.toLowerCase()}`)

/**
 * 实体属性面版
 */
@Component({
  name: 'entityPropertyPanel',
  components: { SlickList, SlickItem }
})
export default class EntityPropertyPanel extends Vue {
  /// 当前打开的面版
  openPanel = ['base']
  /// 是否可拖动排序
  slickable = false

  /// 实体对象
  @Prop({
    type: Object,
    required: true
  })
  readonly value!: Entity

  readOnly = this.lockedOrgIds.findIndex(item => item === this.value.orgId) < 0

  /**
   * 当前数据模型对象
   */
  @Prop({
    type: Object,
    required: true
  })
  readonly entityModelManger!: EntityModelManger

  set curValue (value: Entity) {
    this.$emit('input', value)
  }

  get curValue (): Entity {
    return this.value
  }

  @Watch('curValue.title', { immediate: true })
  onTitlesChanged (newTitle: string, oldTitle: string) {
    if (!newTitle || newTitle === '') {
      this.curValue.title = oldTitle
    }
  }

  @Watch('curValue.name', { immediate: true })
  onNameChanged (newName: string, oldName: string) {
    if (!newName || newName === '' || !/^[a-zA-z].*/.test(newName)) {
      this.curValue.name = oldName.charAt(0).toUpperCase() + oldName.slice(1)
    } else {
      this.curValue.name = newName.charAt(0).toUpperCase() + newName.slice(1)
    }
  }

  @Watch('curValue.uuid')
  onEntityCange () {
    this.openPanel = ['base']
  }

  /// 全名
  get fullName () {
    return getOrgFullName(this.curValue.org)
  }

  /**
   * 取得父节实体名
   */
  get parentName () {
    if (this.curValue.baseEntityUuid) {
      const parent =
        this.entityModelManger.entitiesMap[this.curValue.baseEntityUuid]
      if (parent) {
        return `${parent.title}(${getOrgFullName(parent.org)}.${parent.name})`
      }
    }
    return '(无)'
  }

  /**
   * 取得当前工程的锁定组织
   */
  get lockedOrgIds () {
    return getterLockOrgIds()
  }

  /**
   * 监视锁定列表变化
   */
  @Watch('value')
  WatchLockedOrgIds (value: string[]) {
    /// 检查当前是否已经锁住模型
    this.readOnly =
      this.lockedOrgIds.findIndex(item => item === this.value.orgId) < 0
  }

  /// 处理表格命名
  @Watch('curValue.name')
  watchTableName (name: string) {
    const tableName = toLineName(name)
    this.curValue.tableName = tableName.startsWith('_')
      ? tableName.substring(1)
      : tableName
  }

  /**
   * 添加一个索引
   */
  addIndex () {
    if (this.readOnly) return
    showNewIndexModal(this.curValue, data => {
      return new Promise<void>((resolve, reject) => {
        this.curValue.indexes.push(data)
        resolve()
      })
    })
  }

  /**
   * 编辑一个索引
   */
  editIndex (index: number) {
    if (this.readOnly) return
    showModiIndexModal(
      this.curValue,
      this.curValue.indexes[index] as Index,
      data => {
        return new Promise<void>((resolve, reject) => {
          this.curValue.indexes[index] = data
          resolve()
        })
      }
    )
  }

  /**
   * 删除一个索引
   */
  delIndex (index: number) {
    if (this.readOnly) return
    const title = this.curValue.indexes[index].title
    const name = this.curValue.indexes[index].name
    this.$Modal.confirm({
      title: '确认',
      content: `确认要删除${title}(${name})的索引吗？`,
      onOk: () => {
        this.curValue.indexes.splice(index, 1)
      }
    })
  }

  /**
   * 添加一个约束
   */
  addConstraint () {
    if (this.readOnly) return
    showNewConstraintModal(this.curValue, data => {
      return new Promise<void>((resolve, reject) => {
        this.curValue.constraints.push(data)
        resolve()
      })
    })
  }

  /**
   * 编辑一个约束
   */
  editConstraint (index: number) {
    if (this.readOnly) return
    showModiConstraintModal(
      this.curValue,
      this.curValue.constraints[index] as Constraint,
      data => {
        return new Promise<void>((resolve, reject) => {
          this.curValue.constraints[index] = data
          resolve()
        })
      }
    )
  }

  /**
   * 删除一个约束
   */
  delConstraint (index: number) {
    if (this.readOnly) return
    const title = this.curValue.constraints[index].title
    const name = this.curValue.constraints[index].name
    this.$Modal.confirm({
      title: '确认',
      content: `确认要删除${title}(${name})的约束吗？`,
      onOk: () => {
        this.curValue.constraints.splice(index, 1)
      }
    })
  }

  /// 添加一个左联结
  addLeftjoin () {
    if (this.readOnly) return
    showNewLeftJoinModal(this.entityModelManger, this.curValue, data => {
      return new Promise<void>((resolve, reject) => {
        resolve()
        // 通知添加了一个新的左联结
        this.$emit('onAddLeftjoin', data)
      })
    })
  }

  /// 修改一个左联结
  editLeftjoin (index: number) {
    if (this.readOnly) return
    const join = this.curValue.joins[index]

    showEditLeftJoinModal(join, this.entityModelManger, this.curValue, data => {
      return new Promise<void>((resolve, reject) => {
        resolve()
        this.$emit('onEditLeftjoin', data)
      })
    })
  }

  /// 删除联结
  delLeftjoin (index: number) {
    if (this.readOnly) return
    const that = this
    this.$Modal.confirm({
      title: '确认',
      content: '确认要删除联结吗？',
      onOk: () => {
        const join = this.curValue.joins[index]
        that.$emit('onDelLeftjoin', join)
      }
    })
  }

  /**
   * 检查联结的合法性
   * @param index 尝试删除指定的字段
   * @returns 失败返回false
   */
  checkLeftJoinAlias (entity: Entity, index?: number) {
    /// 找出所有的主表出现的别名是否都在从表中出现过
    const join = _.cloneDeep(entity.joins)
    // 尝试删除
    if (index) {
      join.splice(index, 1)
    }
    const jAlias = entity.joins
      .map(item => item.joinAlias)
      .filter(item => item !== undefined)
    const mAlias = entity.joins
      .map(item => item.mainEntityAlias)
      .filter(item => item !== undefined)

    for (const a of mAlias) {
      if (!_.includes(jAlias, a)) return false
    }
    return true
  }

  /// 设置父实体
  editParentEntity () {
    if (this.readOnly) return
    showSetParentEntityModal(
      this.curValue.uuid,
      this.curValue.baseEntityUuid,
      this.entityModelManger,
      data => {
        return new Promise<void>((resolve, reject) => {
          const change = this.curValue.baseEntityUuid !== data

          resolve()
          /// 如果父实体变化，则触发事件
          if (change) {
            this.$emit('entityParentChange', this.curValue.uuid, data)
          }
        })
      }
    )
  }

  /// 修改实体所在包
  editEntityPackage () {
    if (this.readOnly) return

    showSetEntityPackageModal(
      this.entityModelManger.orgs,
      this.value.orgId,
      newOrgId => {
        return new Promise<void>((resolve, reject) => {
          if (this.value.orgId !== newOrgId) {
            this.$emit(
              'entityMove',
              this.value.uuid,
              this.value.orgId,
              newOrgId
            )
          }
          resolve()
        })
      }
    )
  }

  /// 取得uuid对应的实体对象
  private getEntityByUuid (uuid: string) {
    return this.entityModelManger.entitiesMap[uuid]
  }

  /// 取得实体的字段
  private getEntityFieldByUuid (entity: Entity, uuid: string) {
    const allFields = this.entityModelManger.getEntityAllFieldes(entity.uuid)
    const index = this.entityModelManger
      .getEntityAllFieldes(entity.uuid)
      .findIndex(e => e.uuid === uuid)
    return ~index ? allFields[index] : undefined
  }

  /// 取得表与字段的信息
  private getTableAndFieldInfo (tableUuid: string, fieldUuid: string) {
    const entity = this.getEntityByUuid(tableUuid)
    if (!entity) return '实体已经删除'
    const field = this.getEntityFieldByUuid(entity, fieldUuid)
    return `${entity.title}(${entity.name}) 字段：${field?.title}(${field?.name})`
  }

  /**
   * 拖动排序完成
   */
  onSlickFinish (field: any) {
    this.slickable = false
  }

  sortLeftjoin () {
    if (this.readOnly) return
    this.slickable = true
  }

  /** 删除检查按钮回调 */
  addDelCheck (item?: DelCheck, index?: number) {
    showDeleteCheckModal(this.entityModelManger, this.curValue, item, data => {
      // 编辑
      if (typeof index === 'number') {
        this.$set(this.curValue.delCheck!, index, data)
      } else {
        // 新增
        this.curValue.delCheck
          ? this.curValue.delCheck.push(data)
          : this.$set(this.curValue, 'delCheck', [data])
      }
      return Promise.resolve()
    })
  }

  deleteDelCheck (index: number) {
    this.$Modal.confirm({
      title: '确认',
      content: '确认要删除删除检查项吗？',
      onOk: () => {
        this.curValue.delCheck?.splice(index, 1)
        // 为空时删除
        if (this.curValue.delCheck?.length === 0) {
          this.$set(this.curValue, 'delCheck', undefined)
        }
      }
    })
  }

  getEntityNameByUuid (entityUuid: string) {
    return this.entityModelManger.getEntityByUuid(entityUuid)?.name
  }

  getFieldNameByUuid (entityUuid: string, fieldUuid: string) {
    return this.entityModelManger
      .getEntityAllFieldes(entityUuid)
      .find(e => e.uuid === fieldUuid)?.name
  }
}
