import EntityModelManger from '@/libs/entitymodel'
import Entity from '@/libs/entitymodel/entity'
import DelCheck from '@/libs/entitymodel/entity-delcheck'
import { showModal } from '@/libs/modal-helper'
import DeleteCheckModal from './delete-check-modal.vue'

/**
 * 弹出新增约束对话框
 * @param parent
 * @param onOk
 */
export function showDeleteCheckModal (entityModelManger: EntityModelManger, entity: Entity,
  delCheck?: DelCheck, onOk?: (data: DelCheck) => Promise<void>) {
  showModal(DeleteCheckModal, {
    props: {
      entityModelManger: entityModelManger,
      entity: entity,
      value: delCheck
    }
  }, true, onOk, undefined)
}
